
import { useState, useEffect } from "react";
import _ from "lodash";

import { TempTextInput } from "../Input/TempTextInput"
import { TempAutoSelectInput } from "../Input/TempAutoSelectInput";
import { constants } from '../../Constants/constants';

import "../../styles/uploadPayment.scss";

let timeoutHandler = undefined;

export const ListFilter = ({ listFilters, onChangeListFilters, handleFilterExtraChange }) => {

  const [list, setList] = useState(listFilters);

  const handleChange = (key, value) => {
    const tempList = _.cloneDeep(list);

    const foundFilter = tempList.find((filterItem) => {
      return filterItem.name === key;
    })

    if (foundFilter) {
      foundFilter.value = value;
    }

    if (handleFilterExtraChange) {
      handleFilterExtraChange(tempList);
    }

    setList(tempList);

    if (timeoutHandler) {
      clearTimeout(timeoutHandler);
    }
    const newTimeoutHandler = setTimeout(function () {
      onChangeListFilters(tempList);
    }, 2000);
    timeoutHandler = newTimeoutHandler;
  }

  useEffect(() => {
    return () => {
      if (timeoutHandler) {
        clearTimeout(timeoutHandler);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=>{
    setList(listFilters)
  }, [listFilters])

  return (
    <>
      {list.map((item, index) => {
        return item.type === constants.TYPE.SELECT ?
          <TempAutoSelectInput key={index} inputSetting={item} handleChange={handleChange} index={index} />
          : <TempTextInput key={index} index={index} label="filter" inputSetting={item} handleChange={handleChange} />
      })
      }
    </>
  )
};