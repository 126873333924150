import { useState, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useParams } from 'react-router-dom'
import { sify } from 'chinese-conv'
import moment from 'moment'
import _ from 'lodash'

import CancelIcon from '@mui/icons-material/Cancel'
import ClearIcon from '@mui/icons-material/Clear'
import { makeStyles } from '@mui/styles'
import Paper from '@mui/material/Paper'
import { Drawer } from '@mui/material'

import { getOne } from '../../helper/baseFetch'
import { constants as co, BANNER_ACTION, BANNER_TYPE, PICTURE_SIZE } from '../../Constants/constants'
import { setBanner, setBannerList } from '../../actions/bannerAction'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { TempAutoSelectInput } from '../../Component/Input/TempAutoSelectInput'
import { TempCustomCheckBox } from '../../Component/Custom/TempCustomCheckBox'
import { TempBtn } from '../../Component/Input/TempBtn'
// import { TempCheckBox } from "../../Component/Input/TempCheckBox";
import BannerValidation from '../../validations/bannerValidation'
import { bannerValidationConstants } from '../../Constants/validationConstants'
import { handleActionParameters } from '../../Component/ActionParameters/handleActionParameters'
import { ConfigMapCreate } from '../Ticket/ConfigMapCreate'
import { UploadImage } from '../../Component/UploadImage/UploadImage'
import { BannerCreateAction } from './BannerCreateAction'
import { handleParameters, handleParametersInputBox } from './handleParameters'
import { FolderList } from '../Folder/FolderList'
import { RestaurantList } from '../Restaurant/RestaurantList'
import { ProductList } from '../../Component/ProductCreate/Product/ProductList'
import { ProductCategoryList } from '../../Component/ProductCreate/Category/ProductCategoryList'
import { BrandList } from '../Brand/BrandList'

const helperTextStyles = makeStyles((theme) => ({
  root: {
    color: 'red !important',
  },
}))

const LOCAL_CONSTANT = {
  ALL_IMAGE: 'allImage',
  EN_IMAGE: 'enImage',
  TC_IMAGE: 'tcImage',
  SC_IMAGE: 'scImage',
}

export const BannerCreateTemp = ({ display, create, bannerType, dataId, handleApiCallBack, newPath, clear, setClear, ready, setReady }) => {
  const dispatch = useDispatch()
  const store = useStore()
  const params = useParams()
  const savedBannerList = useSelector((state) => state.banner.ui.bannerList)
  const savedBanner = useSelector((state) => state.banner.ui.banner)
  const [bannerInputList, setBannerInputList] = useState(_.cloneDeep(savedBanner))
  const [drawerType, setDrawerType] = useState('')
  const [drawerIndex, setDrawerIndex] = useState('')
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [openRefCodeDrawer, setOpenRefCodeDrawer] = useState(false)
  const helperTextStylesClasses = helperTextStyles()

  const size = PICTURE_SIZE.MID

  const resource = 'Banner'

  const getActionTypeChoices = () => {
    switch (savedBanner.type) {
      case BANNER_TYPE.MERCHANT_LANDING:
      case BANNER_TYPE.MERCHANT_LOGIN:
        return co.CHOICES.BANNER.MERCHANT_ACTION_TYPE
      default:
        return co.CHOICES.BANNER.ACTION_TYPE
    }
  }

  const fields = [
    {
      variant:
        (!create && !bannerType && !savedBanner.type.startsWith('LANDING')) || (bannerType && bannerType !== 'LANDING')
          ? co.STYLE.VARIANT.STANDARD
          : '',
      label: { resource: resource, key: 'type' },
      value: savedBanner.type,
      name: 'type',
      type: (!create && !bannerType && !savedBanner.type.startsWith('LANDING')) || (bannerType && bannerType !== 'LANDING') ? '' : co.TYPE.SELECT,
      selectValue: bannerType !== 'LANDING' ? co.CHOICES.BANNER.TYPE : co.CHOICES.BANNER.LANDING_BANNER_TYPE,
      disabled: (!create && !bannerType && !savedBanner.type.startsWith('LANDING')) || (bannerType && bannerType !== 'LANDING') ? true : false,
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: BannerValidation.getErrorMessages(bannerValidationConstants.KEY_TYPE, savedBanner.customId ? savedBanner.customId : ''),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    !bannerType &&
      savedBanner.type &&
      (savedBanner.type === BANNER_TYPE.RESTAURANT ||
        savedBanner.type === BANNER_TYPE.FOLDER ||
        savedBanner.type === BANNER_TYPE.MERCHANT_LANDING) && {
        label: { resource: resource, key: 'refCode' },
        value: savedBanner.refCode,
        name: 'refCode',
        onClickEndAdornmentIconButton: () => onClickRefCodeEndAdornmentIconButton(),
        isEndOfLine: true,
        disabled: true,
        helperTextIcon: true,
        helperText: BannerValidation.getErrorMessages(bannerValidationConstants.KEY_REF_CODE, savedBanner.customId ? savedBanner.customId : ''),
        FormHelperTextProps: { className: helperTextStylesClasses.root },
      },
    {
      label: { resource: resource, key: 'startDisplayDate' },
      value: savedBanner.startDisplayDate,
      name: 'startDisplayDate',
      type: co.TYPE.DATETIME_LOCAL,
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: BannerValidation.getErrorMessages(
        bannerValidationConstants.KEY_START_DISPLAY_DATE,
        savedBanner.customId ? savedBanner.customId : ''
      ),
      step: 1,
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      label: { resource: resource, key: 'endDisplayDate' },
      value: savedBanner.endDisplayDate,
      name: 'endDisplayDate',
      type: co.TYPE.DATETIME_LOCAL,
      isEndOfLine: false,
      required: true,
      step: 1,
      helperTextIcon: true,
      helperText: BannerValidation.getErrorMessages(bannerValidationConstants.KEY_END_DISPLAY_DATE, savedBanner.customId ? savedBanner.customId : ''),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      label: { resource: resource, key: 'enName' },
      value: savedBanner.enName,
      name: 'enName',
      custom: true,
      customName: { key: 'nameCustomize' },
      customValue: savedBanner.scNameDisable,
      isEndOfLine: false,
    },
    {
      label: { resource: resource, key: 'tcName' },
      value: savedBanner.tcName,
      name: 'tcName',
      translateName: savedBanner.scNameDisable ? ['scName'] : null,
      inputList: bannerInputList[savedBanner.customId],
      setInputList: handleTcNameState,
      isEndOfLine: false,
    },
    {
      label: { resource: resource, key: 'scName' },
      value: savedBanner.scName,
      name: 'scName',
      inputList: bannerInputList[savedBanner.customId],
      setInputList: handleScNameState,
      isEndOfLine: true,
      disabled: savedBanner.scNameDisable,
    },
    {
      label: { resource: resource, key: 'sequence' },
      value: savedBanner.sequence,
      name: 'sequence',
      isEndOfLine: savedBanner.id ? false : true,
      //eslint-disable-next-line
      pattern: '^d*$',
      required: true,
      helperTextIcon: true,
      helperText: BannerValidation.getErrorMessages(bannerValidationConstants.KEY_SEQUENCE, savedBanner.customId ? savedBanner.customId : ''),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    savedBanner.id && {
      label: { resource: resource, key: 'status' },
      value: savedBanner.status,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.BANNER.STATUS,
      name: 'status',
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: BannerValidation.getErrorMessages(bannerValidationConstants.KEY_STATUS, savedBanner.customId ? savedBanner.customId : ''),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      picture: savedBanner.enImage,
      label: { resource: resource, key: 'enImage' },
      name: 'enImage',
      type: co.TYPE.IMAGE,
      uploadImagePath: co.PATH.BANNER.UPLOAD_BANNER_IMAGE,
      saveImage: handleSaveImage,
      isEndOfLine: false,
      required: true,
    },
    {
      picture: savedBanner.tcImage,
      label: { resource: resource, key: 'tcImage' },
      name: 'tcImage',
      type: co.TYPE.IMAGE,
      uploadImagePath: co.PATH.BANNER.UPLOAD_BANNER_IMAGE,
      saveImage: handleSaveImage,
      isEndOfLine: false,
      required: true,
    },
    {
      picture: savedBanner.scImage,
      label: { resource: resource, key: 'scImage' },
      name: 'scImage',
      type: co.TYPE.IMAGE,
      uploadImagePath: co.PATH.BANNER.UPLOAD_BANNER_IMAGE,
      saveImage: handleSaveImage,
      isEndOfLine: true,
      required: true,
    },
    {
      label: { resource: resource, key: 'actionType' },
      value: savedBanner.actionType,
      type: co.TYPE.SELECT,
      selectValue: getActionTypeChoices(),
      name: 'actionType',
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: BannerValidation.getErrorMessages(bannerValidationConstants.KEY_ACTION_TYPE, savedBanner.customId ? savedBanner.customId : ''),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      type: co.TYPE.FUNCTION,
      custom:
        savedBanner.actionType && savedBanner.actionType !== BANNER_ACTION.NONE && savedBanner.actionType !== BANNER_ACTION.DOWNLOAD ? true : false,
      customName: { key: 'multiActionParameters' },
      customValue: savedBanner.multiActionParameters,
      value: (
        <>
          {savedBanner.actionType && savedBanner.actionType !== BANNER_ACTION.NONE && savedBanner.actionType !== BANNER_ACTION.DOWNLOAD && (
            <BannerCreateAction
              onChangeInput={onChangeInput}
              handleParameters={handleParameters}
              drawerOpen={drawerOpen}
              setDrawerOpen={setDrawerOpen}
              drawerIndex={drawerIndex}
              setDrawerIndex={setDrawerIndex}
              drawerType={drawerType}
              setDrawerType={setDrawerType}
            />
          )}
        </>
      ),
      isEndOfLine: true,
    },
    !savedBanner.id &&
      savedBanner.type === BANNER_TYPE.TICKET && {
        type: co.TYPE.FUNCTION,
        value: <ConfigMapCreate type={resource} toolBar={false} />,
        isEndOfLine: true,
      },
  ].filter((tabTitle) => tabTitle)

  const content = [
    {
      tab: (
        <>
          <>
            {BannerValidation.getErrorMessages(bannerValidationConstants.KEY_BANNER_ERROR) &&
              BannerValidation.getErrorMessages(bannerValidationConstants.KEY_BANNER_ERROR).length > 0 && (
                <div className="error-message">
                  <CancelIcon className="error-message-icon" fontSize={co.SIZE.SMALL} />
                  {BannerValidation.getErrorMessages(bannerValidationConstants.KEY_BANNER_ERROR)}
                </div>
              )}
          </>
          {fields.map((inputSetting, index) => {
            let content
            switch (inputSetting.type) {
              case co.TYPE.SELECT:
                content = <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} index={savedBanner.customId} />
                break
              case co.TYPE.IMAGE:
                content = (
                  <div className="image">
                    <UploadImage
                      picture={inputSetting.picture}
                      title={inputSetting.label}
                      name={inputSetting.name}
                      uploadImagePath={inputSetting.uploadImagePath}
                      saveImage={inputSetting.saveImage}
                      index={savedBanner.customId}
                      size={size}
                      showApplyToAll={true}
                      required={inputSetting.required}
                    />
                  </div>
                )
                break
              case co.TYPE.FUNCTION:
                content = inputSetting.value
                break
              case co.TYPE.TEXT:
                content = inputSetting.value ? <div className={inputSetting.className}>{inputSetting.value}</div> : <></>
                break
              default:
                content = <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} index={savedBanner.customId} />
            }
            return (
              <div className="edit-display-wrapper" key={index}>
                {inputSetting.name === 'enImage' && (
                  <>
                    <>
                      {BannerValidation.getErrorMessages(bannerValidationConstants.KEY_TC_IMAGE, savedBanner.customId ? savedBanner.customId : '') &&
                        BannerValidation.getErrorMessages(bannerValidationConstants.KEY_TC_IMAGE, savedBanner.customId ? savedBanner.customId : '')
                          .length > 0 && (
                          <div className="error-message">
                            <CancelIcon className="error-message-icon" fontSize={co.SIZE.SMALL} />
                            {BannerValidation.getErrorMessages(
                              bannerValidationConstants.KEY_TC_IMAGE,
                              savedBanner.customId ? savedBanner.customId : ''
                            )}
                          </div>
                        )}
                    </>
                    <>
                      {BannerValidation.getErrorMessages(bannerValidationConstants.KEY_SC_IMAGE, savedBanner.customId ? savedBanner.customId : '') &&
                        BannerValidation.getErrorMessages(bannerValidationConstants.KEY_SC_IMAGE, savedBanner.customId ? savedBanner.customId : '')
                          .length > 0 && (
                          <div className="error-message">
                            <CancelIcon className="error-message-icon" fontSize={co.SIZE.SMALL} />
                            {BannerValidation.getErrorMessages(
                              bannerValidationConstants.KEY_SC_IMAGE,
                              savedBanner.customId ? savedBanner.customId : ''
                            )}
                          </div>
                        )}
                    </>
                    <>
                      {BannerValidation.getErrorMessages(bannerValidationConstants.KEY_EN_IMAGE, savedBanner.customId ? savedBanner.customId : '') &&
                        BannerValidation.getErrorMessages(bannerValidationConstants.KEY_EN_IMAGE, savedBanner.customId ? savedBanner.customId : '')
                          .length > 0 && (
                          <div className="error-message">
                            <CancelIcon className="error-message-icon" fontSize={co.SIZE.SMALL} />
                            {BannerValidation.getErrorMessages(
                              bannerValidationConstants.KEY_EN_IMAGE,
                              savedBanner.customId ? savedBanner.customId : ''
                            )}
                          </div>
                        )}
                    </>
                  </>
                )}
                {inputSetting.custom && (
                  <TempCustomCheckBox
                    className="customize-btn"
                    value={inputSetting.customValue}
                    customName={inputSetting.customName}
                    onSelectCheckbox={onSelectCheckbox}
                    index={savedBanner.customId}
                  />
                )}
                {content}
                {inputSetting.isEndOfLine && <div></div>}
              </div>
            )
          })}
        </>
      ),
      btn: false,
    },
  ]

  function onClickRefCodeEndAdornmentIconButton() {
    setDrawerType(savedBanner.type)
    setOpenRefCodeDrawer(true)
    setDrawerOpen(true)
  }

  function handleTcNameState(value) {
    let tempBannerList = _.cloneDeep(savedBanner)
    if (tempBannerList.scNameDisable === true) {
      tempBannerList.tcName = _.cloneDeep(value.tcName)
      tempBannerList.scName = value.tcName ? sify(_.cloneDeep(value.tcName)) : value.tcName
    } else {
      tempBannerList.tcName = _.cloneDeep(value.tcName)
    }
    setBannerInputList(_.cloneDeep(tempBannerList))
  }

  function handleScNameState(value) {
    let tempBannerList = _.cloneDeep(savedBanner)
    tempBannerList.scName = value.scName
    setBannerInputList(_.cloneDeep(tempBannerList))
  }

  function handleSaveImage(name, image, index, applyToAll) {
    const tempBanner = _.cloneDeep(savedBanner)
    let tempBannerList = _.cloneDeep(savedBannerList)

    if (applyToAll) {
      tempBanner[LOCAL_CONSTANT.EN_IMAGE] = image
      tempBanner[LOCAL_CONSTANT.SC_IMAGE] = image
      tempBanner[LOCAL_CONSTANT.TC_IMAGE] = image
    } else {
      tempBanner[`${name}`] = image
    }

    dispatch(setBanner(tempBanner))
    tempBannerList = tempBannerList.map((bannerListItem) => {
      if (bannerListItem.customId === tempBanner.customId) {
        bannerListItem = tempBanner
      }
      return bannerListItem
    })
    dispatch(setBannerList(tempBannerList))
  }

  function onSelectCheckbox(value, customName, index) {
    const tempBanner = _.cloneDeep(savedBanner)
    let tempBannerList = _.cloneDeep(savedBannerList)
    switch (customName.key) {
      case 'nameCustomize':
        tempBanner['scNameDisable'] = value
        if (value) {
          tempBanner['scName'] = tempBanner.tcName ? sify(tempBanner.tcName) : tempBanner.tcName
        }
        break
      case 'multiActionParameters':
        tempBanner['multiActionParameters'] = value
        tempBanner['actionParameters'] = tempBanner.tcActionParameters
        tempBanner['enActionParameters'] = tempBanner.tcActionParameters
        tempBanner['scActionParameters'] = tempBanner.tcActionParameters
        tempBanner['enParameters'] = tempBanner.tcParameters
        tempBanner['scParameters'] = tempBanner.tcParameters
        break
      default:
    }
    dispatch(setBanner(tempBanner))
    tempBannerList = tempBannerList.map((bannerListItem) => {
      if (bannerListItem.customId === tempBanner.customId) {
        bannerListItem = tempBanner
      }
      return bannerListItem
    })
    dispatch(setBannerList(tempBannerList))
  }

  function onChangeInput(name, e, index, updateType) {
    //BannerValidation.resetErrorMessages();
    let actionParametersType = null
    let tempParameters = 'tcParameters'
    let tempBanner = _.cloneDeep(savedBanner)
    let tempBannerList = _.cloneDeep(savedBannerList)
    if (index === 0) {
      actionParametersType = 'en'
      tempParameters = 'enParameters'
    } else if (index === 1) {
      actionParametersType = 'tc'
      tempParameters = 'tcParameters'
    } else if (index === 2) {
      actionParametersType = 'sc'
      tempParameters = 'scParameters'
    }
    function checkActionParametersLang(newActionParameters) {
      if (tempBanner.multiActionParameters) {
        switch (actionParametersType) {
          case 'en':
            tempBanner['actionParameters'] = newActionParameters
            tempBanner['enActionParameters'] = newActionParameters
            tempBanner = handleParameters(tempBanner, 'en')
            BannerValidation.resetErrorMessages(bannerValidationConstants.KEY_CHILDCODE_EN)
            BannerValidation.resetErrorMessages(bannerValidationConstants.KEY_PARAMETERS_EN)
            break
          case 'tc':
            tempBanner['actionParameters'] = newActionParameters
            tempBanner['tcActionParameters'] = newActionParameters
            tempBanner = handleParameters(tempBanner, 'tc')
            BannerValidation.resetErrorMessages(bannerValidationConstants.KEY_CHILDCODE_TC)
            BannerValidation.resetErrorMessages(bannerValidationConstants.KEY_PARAMETERS_TC)
            break
          case 'sc':
            tempBanner['actionParameters'] = newActionParameters
            tempBanner['scActionParameters'] = newActionParameters
            tempBanner = handleParameters(tempBanner, 'sc')
            BannerValidation.resetErrorMessages(bannerValidationConstants.KEY_CHILDCODE_SC)
            BannerValidation.resetErrorMessages(bannerValidationConstants.KEY_PARAMETERS_SC)
            break
          default:
            tempBanner['actionParameters'] = newActionParameters
            tempBanner['enActionParameters'] = newActionParameters
            tempBanner['tcActionParameters'] = newActionParameters
            tempBanner['scActionParameters'] = newActionParameters
            tempBanner['enParameters'] = handleParameters(tempBanner).parameters
            tempBanner['tcParameters'] = handleParameters(tempBanner).parameters
            tempBanner['scParameters'] = handleParameters(tempBanner).parameters
        }
      } else {
        tempBanner['actionParameters'] = newActionParameters
        tempBanner['enActionParameters'] = newActionParameters
        tempBanner['tcActionParameters'] = newActionParameters
        tempBanner['scActionParameters'] = newActionParameters
        tempBanner['enParameters'] = handleParameters(tempBanner).parameters
        tempBanner['tcParameters'] = handleParameters(tempBanner).parameters
        tempBanner['scParameters'] = handleParameters(tempBanner).parameters
        BannerValidation.resetErrorMessages(bannerValidationConstants.KEY_PARAMETERS)
        BannerValidation.resetErrorMessages(bannerValidationConstants.KEY_CHILDCODE)
      }
      return tempBanner
    }
    switch (name) {
      case 'tcName':
        if (tempBanner.scNameDisable === true) {
          tempBanner[`${name}`] = e
          tempBanner['scName'] = sify(e)
        } else {
          tempBanner[`${name}`] = e
        }
        break
      case 'type':
        tempBanner[`${name}`] = e
        tempBanner['refCode'] = null
        tempBanner['configMapList'] = []
        break
      case 'refCode':
        switch (tempBanner.type) {
          case BANNER_TYPE.FOLDER:
            tempBanner[`${name}`] = e.folderCode
            break
          case BANNER_TYPE.RESTAURANT:
          case BANNER_TYPE.MERCHANT_LANDING:
            tempBanner[`${name}`] = e.restUrlId
            break
          default:
            tempBanner[`${name}`] = null
        }
        break
      case 'startDisplayDate':
      case 'endDisplayDate':
        tempBanner[`${name}`] = Number(moment(e).format('x'))
        break
      case 'actionType':
        tempBanner[`${name}`] = e
        tempBanner.otherParameters = []
        actionParametersType = ''
        tempParameters = 'tcParameters'
        // if(tempBanner === BANNER_TYPE.MERCHANT_LANDING|| tempBanner === BANNER_TYPE.MERCHANT_LOGIN){
        //   if(tempBanner.actionType === BANNER_ACTION.WEB_VIEW){
        //     tempBanner.actionType = BANNER_ACTION.MERCHANT_WEB_VIEW;
        //   }
        //   if(tempBanner.actionType === BANNER_ACTION.LINK){
        //     tempBanner.actionType = BANNER_ACTION.MERCHANT_LINK;
        //   }
        // }
        tempBanner = checkActionParametersLang(handleActionParameters({ url: '', from: '' }, tempBanner))
        tempBanner = handleParametersInputBox(tempBanner)
        break
      case 'url':
        let newActionParameters = ''
        switch (tempBanner.actionType) {
          case BANNER_ACTION.PRODUCT_CATEGORY:
          case BANNER_ACTION.E_COUPON_CATEGORY:
            newActionParameters = handleActionParameters({ url: e.categoryCode, from: tempBanner[`${tempParameters}`]['from'] }, tempBanner)
            break
          case BANNER_ACTION.PRODUCT:
            if (updateType === BANNER_ACTION.PRODUCT_COUPON_CATEGORY_MAP) {
              newActionParameters = handleActionParameters(
                {
                  url: tempBanner[`${tempParameters}`][`${tempBanner.inputName}`],
                  productParent: tempBanner[`${tempParameters}`]['productParent'],
                  categoryCode: e.categoryCode,
                  type: tempBanner[`${tempParameters}`]['type'],
                  from: tempBanner[`${tempParameters}`]['from'],
                },
                tempBanner
              )
            } else if (updateType === BANNER_ACTION.PRODUCT_COUPON_BRAND_MAP) {
              newActionParameters = handleActionParameters(
                {
                  url: tempBanner[`${tempParameters}`][`${tempBanner.inputName}`],
                  productParent: tempBanner[`${tempParameters}`]['productParent'],
                  brandCode: e.brandCode,
                  type: tempBanner[`${tempParameters}`]['type'],
                  from: tempBanner[`${tempParameters}`]['from'],
                },
                tempBanner
              )
            } else {
              newActionParameters = handleActionParameters({ url: e.productCode, from: tempBanner[`${tempParameters}`]['from'] }, tempBanner)
            }
            break
          case BANNER_ACTION.E_COUPON:
            e.ecouponCode = e.productCode
            if (updateType === BANNER_ACTION.PRODUCT_COUPON_CATEGORY_MAP) {
              newActionParameters = handleActionParameters(
                {
                  url: tempBanner[`${tempParameters}`][`${tempBanner.inputName}`],
                  productParent: tempBanner[`${tempParameters}`]['productParent'],
                  categoryCode: e.categoryCode,
                  type: tempBanner[`${tempParameters}`]['type'],
                  from: tempBanner[`${tempParameters}`]['from'],
                },
                tempBanner
              )
            } else if (updateType === BANNER_ACTION.PRODUCT_COUPON_BRAND_MAP) {
              newActionParameters = handleActionParameters(
                {
                  url: tempBanner[`${tempParameters}`][`${tempBanner.inputName}`],
                  productParent: tempBanner[`${tempParameters}`]['productParent'],
                  brandCode: e.brandCode,
                  type: tempBanner[`${tempParameters}`]['type'],
                  from: tempBanner[`${tempParameters}`]['from'],
                },
                tempBanner
              )
            } else {
              newActionParameters = handleActionParameters({ url: e.ecouponCode, from: tempBanner[`${tempParameters}`]['from'] }, tempBanner)
            }
            break
          case BANNER_ACTION.PRODUCT_BRAND:
          case BANNER_ACTION.E_COUPON_BRAND:
            newActionParameters = handleActionParameters({ url: e.brandCode, from: tempBanner[`${tempParameters}`]['from'] }, tempBanner)
            break
          case BANNER_ACTION.FOLDER:
            newActionParameters = handleActionParameters(
              { url: e.folderCode, folderType: tempBanner[`${tempParameters}`].folderType, from: tempBanner[`${tempParameters}`]['from'] },
              tempBanner
            )
            break
          case BANNER_ACTION.RESTAURANT:
            newActionParameters = handleActionParameters(
              { url: e.restUrlId, from: tempBanner[`${tempParameters}`]['from'], action: tempBanner[`${tempParameters}`]['action'] },
              tempBanner
            )
            break
          // case BANNER_ACTION.PIXEL:
          //   tempBanner = checkActionParametersLang(handleActionParameters({ url: e, name: tempBanner[`${tempParameters}`]["name"], haveRef: tempBanner[`${tempParameters}`]["haveRef"], from: tempBanner[`${tempParameters}`]["from"] }, tempBanner);
          //   break;
          default:
            newActionParameters = handleActionParameters(
              {
                url: e,
                from: tempBanner[`${tempParameters}`]['from'],
                code: tempBanner[`${tempParameters}`]['code'],
                title: tempBanner[`${tempParameters}`]['title'],
                serviceType: tempBanner[`${tempParameters}`]['serviceType'],
                type: tempBanner[`${tempParameters}`]['type'],
              },
              tempBanner
            )
            break
        }
        tempBanner = checkActionParametersLang(newActionParameters)
        break
      case 'folderType':
        tempBanner = checkActionParametersLang(
          handleActionParameters(
            { url: tempBanner[`${tempParameters}`][tempBanner.inputName], folderType: e, from: tempBanner[`${tempParameters}`]['from'] },
            tempBanner
          )
        )
        break
      case 'title':
        tempBanner = checkActionParametersLang(
          handleActionParameters(
            {
              url: tempBanner[`${tempParameters}`][tempBanner.inputName],
              type: tempBanner[`${tempParameters}`]['type'],
              from: tempBanner[`${tempParameters}`]['from'],
              title: e,
            },
            tempBanner
          )
        )
        break
      case 'webViewType':
        tempBanner = checkActionParametersLang(
          handleActionParameters(
            {
              url: tempBanner[`${tempParameters}`][tempBanner.inputName],
              from: tempBanner[`${tempParameters}`]['from'],
              title: tempBanner[`${tempParameters}`]['title'],
              type: e,
            },
            tempBanner
          )
        )
        break
      case 'name':
        tempBanner = checkActionParametersLang(
          handleActionParameters(
            {
              url: tempBanner[`${tempParameters}`][tempBanner.inputName],
              type: tempBanner[`${tempParameters}`]['type'],
              from: tempBanner[`${tempParameters}`]['from'],
              name: e,
              query: tempBanner[`${tempParameters}`]['query'],
            },
            tempBanner
          )
        )
        break
      case 'query':
        tempBanner = checkActionParametersLang(
          handleActionParameters(
            {
              url: tempBanner[`${tempParameters}`][tempBanner.inputName],
              type: tempBanner[`${tempParameters}`]['type'],
              from: tempBanner[`${tempParameters}`]['from'],
              name: tempBanner[`${tempParameters}`]['name'],
              query: e,
            },
            tempBanner
          )
        )
        break
      case 'action':
        tempBanner = checkActionParametersLang(
          handleActionParameters(
            { url: tempBanner[`${tempParameters}`][tempBanner.inputName], from: tempBanner[`${tempParameters}`]['from'], action: e },
            tempBanner
          )
        )
        break
      case 'from':
        tempBanner = checkActionParametersLang(
          handleActionParameters(
            {
              url: tempBanner[`${tempParameters}`][tempBanner.inputName],
              productParent: tempBanner[`${tempParameters}`]['productParent'],
              categoryCode: tempBanner[`${tempParameters}`]['categoryCode'],
              brandCode: tempBanner[`${tempParameters}`]['brandCode'],
              catProductCode: tempBanner[`${tempParameters}`]['productCode'],
              brandProductCode: tempBanner[`${tempParameters}`]['productCode'],
              folderType: tempBanner[`${tempParameters}`]['folderType'],
              title: tempBanner[`${tempParameters}`]['title'],
              type: tempBanner[`${tempParameters}`]['type'],
              name: tempBanner[`${tempParameters}`]['name'],
              query: tempBanner[`${tempParameters}`]['query'],
              action: tempBanner[`${tempParameters}`]['action'],
              from: e,
            },
            tempBanner
          )
        )
        break
      case 'sequence':
        tempBanner[`${name}`] = Number.isInteger(Number(e)) ? Number(e) : 0
        break
      case 'productParent':
        tempBanner[`${tempParameters}`][`${name}`] = e
        tempBanner = checkActionParametersLang(
          handleActionParameters(
            {
              url: tempBanner[`${tempParameters}`][`${tempBanner.inputName}`],
              productParent: e,
              brandCode: tempBanner[`${tempParameters}`]['brandCode'],
              categoryCode: tempBanner[`${tempParameters}`]['categoryCode'],
              from: tempBanner[`${tempParameters}`]['from'],
              action: e,
            },
            tempBanner
          )
        )
        break
      default:
        tempBanner[`${name}`] = e
    }
    dispatch(setBanner(tempBanner))
    tempBannerList = tempBannerList.map((bannerListItem) => {
      if (bannerListItem.customId === tempBanner.customId) {
        bannerListItem = tempBanner
      }
      return bannerListItem
    })
    dispatch(setBannerList(tempBannerList))
  }

  function onClickCloseDrawer() {
    setDrawerOpen(false)
    setOpenRefCodeDrawer(false)
  }

  function onClickListRow(e, index, type) {
    if (type === BANNER_ACTION.CATEGORY_PRODUCT_MAP) {
      onChangeInput(BANNER_ACTION.CATEGORY_PRODUCT_MAP, e, index)
    } else if (type === BANNER_ACTION.BRAND_PRODUCT_MAP) {
      onChangeInput(BANNER_ACTION.BRAND_PRODUCT_MAP, e, index)
    } else if (openRefCodeDrawer && (type === BANNER_TYPE.RESTAURANT || type === BANNER_TYPE.FOLDER || type === BANNER_TYPE.MERCHANT_LANDING)) {
      onChangeInput('refCode', e)
    } else {
      onChangeInput('url', e, index, type)
    }
    onClickCloseDrawer()
  }

  function DrawerComponent() {
    let drawer
    switch (drawerType) {
      case BANNER_ACTION.PRODUCT_CATEGORY:
        drawer = (
          <Paper>
            <ProductCategoryList
              newFilter={{
                typeList: [co.CHOICES.PRODUCT_CREATE.SELECT_CATEGORY_TYPE.PRODUCT, co.CHOICES.PRODUCT_CREATE.SELECT_CATEGORY_TYPE.ALL],
              }}
              editBtn={false}
              createBtn={false}
              onClickListRow={(item) => onClickListRow(item, drawerIndex)}
              dialogFooterBtn={
                <TempBtn
                  btnSetting={{
                    variant: co.STYLE.VARIANT.CONTAINED,
                    color: co.STYLE.PRIMARY,
                    onClick: onClickCloseDrawer,
                    icon: <ClearIcon />,
                    label: { key: 'close' },
                  }}
                />
              }
            />
          </Paper>
        )
        break
      case BANNER_ACTION.E_COUPON_CATEGORY:
        drawer = (
          <Paper>
            <ProductCategoryList
              newFilter={{
                typeList: [co.CHOICES.PRODUCT_CREATE.SELECT_CATEGORY_TYPE.FREE_ECOUPON, co.CHOICES.PRODUCT_CREATE.SELECT_CATEGORY_TYPE.ALL],
              }}
              editBtn={false}
              createBtn={false}
              onClickListRow={(item) => onClickListRow(item, drawerIndex)}
              dialogFooterBtn={
                <TempBtn
                  btnSetting={{
                    variant: co.STYLE.VARIANT.CONTAINED,
                    color: co.STYLE.PRIMARY,
                    onClick: onClickCloseDrawer,
                    icon: <ClearIcon />,
                    label: { key: 'close' },
                  }}
                />
              }
            />
          </Paper>
        )
        break
      case BANNER_ACTION.PRODUCT:
        drawer = (
          <Paper>
            <ProductList
              newFilter={{ excludedTypeList: [co.PRODUCT_TYPE.PACKAGE_SUB_ITEM, co.PRODUCT_TYPE.FREE_ECOUPON_ITEM] }}
              editBtn={false}
              createBtn={false}
              onClickListRow={(item) => onClickListRow(item, drawerIndex)}
              dialogFooterBtn={
                <TempBtn
                  btnSetting={{
                    variant: co.STYLE.VARIANT.CONTAINED,
                    color: co.STYLE.PRIMARY,
                    onClick: onClickCloseDrawer,
                    icon: <ClearIcon />,
                    label: { key: 'close' },
                  }}
                />
              }
            />
          </Paper>
        )
        break
      case BANNER_ACTION.E_COUPON:
        drawer = (
          <Paper>
            <ProductList
              newFilter={{ type: co.PRODUCT_TYPE.FREE_ECOUPON_ITEM, excludedTypeList: [co.PRODUCT_TYPE.PACKAGE_SUB_ITEM] }}
              selectValue={co.PRODUCT_TYPE.FREE_ECOUPON_ITEM}
              editBtn={false}
              createBtn={false}
              onClickListRow={(item) => onClickListRow(item, drawerIndex)}
              dialogFooterBtn={
                <TempBtn
                  btnSetting={{
                    variant: co.STYLE.VARIANT.CONTAINED,
                    color: co.STYLE.PRIMARY,
                    onClick: onClickCloseDrawer,
                    icon: <ClearIcon />,
                    label: { key: 'close' },
                  }}
                />
              }
            />
          </Paper>
        )
        break
      case BANNER_ACTION.PRODUCT_BRAND:
      case BANNER_ACTION.E_COUPON_BRAND:
        drawer = (
          <Paper>
            <BrandList
              editBtn={false}
              createBtn={false}
              onClickListRow={(item) => onClickListRow(item, drawerIndex)}
              dialogFooterBtn={
                <TempBtn
                  btnSetting={{
                    variant: co.STYLE.VARIANT.CONTAINED,
                    color: co.STYLE.PRIMARY,
                    onClick: onClickCloseDrawer,
                    icon: <ClearIcon />,
                    label: { key: 'close' },
                  }}
                />
              }
            />
          </Paper>
        )
        break
      case BANNER_ACTION.PRODUCT_COUPON_CATEGORY_MAP:
        drawer = (
          <Paper>
            <ProductCategoryList
              newFilter={
                drawerIndex === 0
                  ? { productCode: savedBanner.enParameters[savedBanner.inputName] }
                  : drawerIndex === 1
                  ? { productCode: savedBanner.tcParameters[savedBanner.inputName] }
                  : drawerIndex === 2
                  ? { productCode: savedBanner.scParameters[savedBanner.inputName] }
                  : { productCode: savedBanner.tcParameters[savedBanner.inputName] }
              }
              newPath={co.PATH.PRODUCT.PRODUCT_CATEGORY_MAP}
              drawerIndex={drawerIndex}
              editBtn={false}
              createBtn={false}
              onClickListRow={(item) => onClickListRow(item, drawerIndex, BANNER_ACTION.PRODUCT_COUPON_CATEGORY_MAP)}
              resource={resource}
              dialogFooterBtn={
                <TempBtn
                  btnSetting={{
                    variant: co.STYLE.VARIANT.CONTAINED,
                    color: co.STYLE.PRIMARY,
                    onClick: onClickCloseDrawer,
                    icon: <ClearIcon />,
                    label: { key: 'close' },
                  }}
                />
              }
            />
          </Paper>
        )
        break
      case BANNER_ACTION.PRODUCT_COUPON_BRAND_MAP:
        drawer = (
          <Paper>
            <BrandList
              editBtn={false}
              createBtn={false}
              newPath={co.PATH.PRODUCT.PRODUCT_BRAND_MAP}
              newFilter={
                drawerIndex === 0
                  ? { productCode: savedBanner.enParameters[savedBanner.inputName] }
                  : drawerIndex === 1
                  ? { productCode: savedBanner.tcParameters[savedBanner.inputName] }
                  : drawerIndex === 2
                  ? { productCode: savedBanner.scParameters[savedBanner.inputName] }
                  : { productCode: savedBanner.tcParameters[savedBanner.inputName] }
              }
              onClickListRow={(item) => onClickListRow(item, drawerIndex, BANNER_ACTION.PRODUCT_COUPON_BRAND_MAP)}
              dialogFooterBtn={
                <TempBtn
                  btnSetting={{
                    variant: co.STYLE.VARIANT.CONTAINED,
                    color: co.STYLE.PRIMARY,
                    onClick: onClickCloseDrawer,
                    icon: <ClearIcon />,
                    label: { key: 'close' },
                  }}
                />
              }
            />
          </Paper>
        )
        break
      case BANNER_ACTION.FOLDER:
      case BANNER_TYPE.FOLDER:
        drawer = (
          <Paper>
            <FolderList
              editBtn={false}
              createBtn={false}
              onClickListRow={(item) => onClickListRow(item, drawerIndex, drawerType)}
              dialogFooterBtn={
                <TempBtn
                  btnSetting={{
                    variant: co.STYLE.VARIANT.CONTAINED,
                    color: co.STYLE.PRIMARY,
                    onClick: onClickCloseDrawer,
                    icon: <ClearIcon />,
                    label: { key: 'close' },
                  }}
                />
              }
            />
          </Paper>
        )
        break
      case BANNER_ACTION.RESTAURANT:
      case BANNER_TYPE.RESTAURANT:
      case BANNER_TYPE.MERCHANT_LANDING:
        drawer = (
          <Paper>
            <RestaurantList
              editBtn={false}
              createBtn={false}
              btnList={false}
              onClickListRow={(item) => onClickListRow(item, drawerIndex, drawerType)}
              dialogFooterBtn={
                <TempBtn
                  btnSetting={{
                    variant: co.STYLE.VARIANT.CONTAINED,
                    color: co.STYLE.PRIMARY,
                    onClick: onClickCloseDrawer,
                    icon: <ClearIcon />,
                    label: { key: 'close' },
                  }}
                />
              }
            />
          </Paper>
        )
        break
      default:
        drawer = <></>
        break
    }
    return drawer
  }

  useEffect(() => {
    if (clear === true) {
      getOne(
        newPath ? newPath + '/' + dataId : co.PATH.BANNER.BANNER + '/' + params.id,
        undefined,
        (payload) => {
          if (payload.tcActionParameters === payload.enActionParameters && payload.tcActionParameters === payload.scActionParameters) {
            payload.multiActionParameters = false
          } else {
            payload.multiActionParameters = true
          }
          if (payload.actionType === BANNER_ACTION.CATEGORY_PRODUCT) {
            payload.actionType = BANNER_ACTION.PRODUCT
          }

          if (payload.actionType === BANNER_ACTION.BRAND_PRODUCT) {
            payload.actionType = BANNER_ACTION.PRODUCT
          }

          if (payload.actionType === BANNER_ACTION.CATEGORY) {
            payload.actionType = BANNER_ACTION.PRODUCT_CATEGORY
          }

          if (payload.actionType === BANNER_ACTION.BRAND) {
            payload.actionType = BANNER_ACTION.PRODUCT_BRAND
          }

          if (payload.type === BANNER_TYPE.MERCHANT_LANDING || payload.type === BANNER_TYPE.MERCHANT_LOGIN) {
            if (payload.actionType === BANNER_ACTION.WEB_VIEW) {
              payload.actionType = BANNER_ACTION.MERCHANT_WEB_VIEW
            }

            if (payload.actionType === BANNER_ACTION.LINK) {
              payload.actionType = BANNER_ACTION.MERCHANT_LINK
            }
          }

          payload = handleApiCallBack(payload)
          dispatch(setBanner(payload))
          setReady(true)
          setClear(false)
        },
        undefined,
        store
      )
    }
    BannerValidation.resetErrorMessages()
  }, [clear]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready && (
      <>
        <EditCreateDisplay
          code={savedBanner && savedBanner.customId ? savedBanner.customId : ''}
          resource={resource}
          tabs={false}
          content={content}
          onChangeInput={onChangeInput}
          display={display ? display : savedBanner.customId ? 'edit' : 'create'}
        />
        <Drawer classes={{ paper: 'drawer-margin-left' }} anchor="right" open={drawerOpen} onClose={onClickCloseDrawer}>
          {DrawerComponent()}
        </Drawer>
      </>
    )
  )
}
