import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { STATUS, constants as co } from '../../Constants/constants'

import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import WarningIcon from '@mui/icons-material/Warning'
import CancelIcon from '@mui/icons-material/Cancel'
import { makeStyles } from '@mui/styles'
import { getString } from '../../helper/util'

import variables from '../../styles/globalVariables.scss'
import { Clear, Lens } from '@mui/icons-material'

const { blackColor, subItemColor, middleGrayColor } = variables

/**
 * @typedef {Object} inputSetting
 * @property freeSolo
 * @property value
 * @property selectValue
 * @property size
 * @property multiple
 * @property className
 * @property name
 * @property disabled
 * @property disableClearable
 * @property label
 * @property helperTextIcon
 * @property helperText
 * @property FormHelperTextProps
 * @property multiline
 * @property required
 * @property customEndAdornmentClearIcon
 * @property freeSoloUppercase
 * @property isColorFieldSpecialCase
 * @property disableStatusThemeColor
 */

const iconTextStyle = makeStyles(() => ({
  icon: {
    '& .MuiIconButton-root': {
      color: `${subItemColor}`,
    },
  },
}))

const normalTextStyle = makeStyles(() => ({
  label: {
    '& .MuiFormLabel-root': {
      color: `${blackColor}`,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${blackColor}`,
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: 'rgba(18, 18, 18, 0.54)',
    },
  },
}))

const errorStyle = makeStyles(() => ({
  root: {
    color: 'red !important',
  },
}))

const trueValueStyle = makeStyles(() => ({
  label: {
    '& .MuiInputBase-root': {
      color: `#2e7d32`,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `#4caf50`,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#4caf50',
    },
  },
}))

export const TempAutoSelectInput = ({ inputSetting: p, index, handleChange, dataList }) => {
  const [localValue, setLocalValue] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [isFocused, setIsFocused] = useState(false)
  const iconTextStyleClasses = iconTextStyle()
  const normalTextStyleClasses = normalTextStyle()
  const trueTextStyleClasses = trueValueStyle()
  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)

  if (!p.className) {
    p.className = 'edit-create-input'
  }

  if (!p.helperTextIcon) {
    p.helperTextIcon = true
  }

  if (!p.required) {
    p.required = false
  }

  const getLabelStyle = () => {
    if (p.disableStatusThemeColor) {
      return ''
    }

    switch (p.value) {
      case true:
      case STATUS.ACTIVE:
        return trueTextStyleClasses.label
      default:
        return ''
    }
  }

  useEffect(() => {
    if (p.freeSolo) {
      setLocalValue({ name: p.value, value: p.value })
    } else if (p.multiple) {
      setLocalValue(p.value)
    } else {
      setLocalValue(
        p.selectValue
          ? p.selectValue.filter((item) => item.value === p.value).length === 1 && p.selectValue.filter((item) => item.value === p.value)[0]
          : []
      )
    }
  }, [p.value]) // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   console.log('localValue', localValue)
  // }, [localValue]) // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   console.log('p.selectValue', p.selectValue)
  // }, [p.selectValue])

  return (
    <Autocomplete
      freeSolo={p.freeSolo}
      size={p.size || co.SIZE.SMALL}
      multiple={p.multiple}
      filterSelectedOptions={p.multiple}
      options={p.selectValue}
      groupBy={(option) => option.group}
      getOptionDisabled={(option) => option.isDisabled}
      getOptionLabel={(option) => {
        let optionLabel = ''
        if (option) {
          if (option.name) {
            if (option.name.key) {
              optionLabel = getString(lang, option.name.resource, option.name.key, theme)
            } else {
              optionLabel = option.name
            }
          }
        }
        return optionLabel
      }}
      // disablePortal={true}
      renderOption={(props, option) => {
        return (
          <li {...props} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'nowrap' }}>
            <div>
              {option ? (option.name ? (option.name.key ? getString(lang, option.name.resource, option.name.key, theme) : option.name) : '') : ''}
              {option.tooltip && (
                <Tooltip
                  className="white-space special-button-margin-left"
                  title={<span style={{ whiteSpace: 'pre-line', fontSize: '12px' }}>{option.tooltip}</span>}
                  arrow
                >
                  <IconButton style={{ padding: 0, color: '#fce903' }} size={co.SIZE.SMALL}>
                    <WarningIcon />
                  </IconButton>
                </Tooltip>
              )}
              {option.tableTypeName && <span style={{ color: `${middleGrayColor}` }}>{option.tableTypeName}</span>}
            </div>
            {option.hexList && option.hexList.map((hex, idx) => <Lens key={`${hex}-${idx}`} sx={{ color: hex || '#fff' }} />)}
          </li>
        )
      }}
      className={`edit-select-input ${p.className} ${iconTextStyleClasses.icon} ${normalTextStyleClasses.label} ${getLabelStyle()}`}
      value={localValue ? localValue : p.multiple === true ? [] : null}
      onChange={(event, newInputValue) => {
        // console.log('onChange localValue', localValue)
        // console.log('onChange newInputValue', newInputValue)
        handleChange &&
          handleChange(p.name, newInputValue ? (p.multiple ? newInputValue.map((input) => input.value) : newInputValue.value) : '', index, dataList)
      }}
      disableClearable={p.disableClearable || p.customEndAdornmentClearIcon}
      // disableClearable={inputSetting.disableClearable}
      disabled={p.disabled}
      onInputChange={(event, newInputValue) => {
        // console.log('onInputChange localValue', localValue)
        // console.log('onInputChange newInputValue', newInputValue)
        if (p.freeSolo && newInputValue !== localValue?.value) {
          if (localValue?.value === null && p.isColorFieldSpecialCase) {
            handleChange && handleChange(p.name, localValue?.value || newInputValue, index, dataList)
          } else if (p.freeSoloUppercase) {
            handleChange && handleChange(p.name, newInputValue.toUpperCase(), index, dataList)
          } else if (p.multiple) {
            handleChange && handleChange(p.name, p.value ? [...p.value, newInputValue.value] : [newInputValue.value], index, dataList)
          } else {
            handleChange && handleChange(p.name, newInputValue, index, dataList)
          }
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={getString(lang, p.label.resource, p.label.key, theme)}
          variant={co.STYLE.VARIANT.OUTLINED}
          helperText={
            p.helperText &&
            p.helperText.length > 0 && (
              <span className="error-message">
                {p.helperTextIcon && <CancelIcon className="error-message-icon" fontSize={co.SIZE.SMALL} />}
                {p.helperText}
              </span>
            )
          }
          // FormHelperTextProps={p.FormHelperTextProps || errorStyle}
          multiline={p.multiline}
          required={p.required}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          InputProps={{
            ...params.InputProps,
            endAdornment: p.endAdornmentIconButton ? (
              <>
                {params.InputProps.endAdornment}
                <IconButton color={co.STYLE.PRIMARY} size={co.SIZE.SMALL}>
                  {p.endAdornmentIconButton}
                </IconButton>
                {p.customEndAdornmentClearIcon && (
                  <IconButton onClick={() => handleChange && handleChange(p.name, null, index, dataList)} size={co.SIZE.SMALL}>
                    <Clear fontSize={co.SIZE.SMALL} />
                  </IconButton>
                )}
              </>
            ) : (
              params.InputProps.endAdornment
            ),
          }}
        />
      )}
    />
  )
}
