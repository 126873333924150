import { LANDING_ACTION, LANDING_ACTION_PARAMETER } from '../../../Constants/constants'
import queryString from 'query-string'

export function handleLandingActionParameters(e, item) {
  let actionParameters = ''
  switch (item.displayActionType) {
    case LANDING_ACTION_PARAMETER.WEB_VIEW:
    case LANDING_ACTION_PARAMETER.VIDEO:
      const webString = [
        e.action ? `action=${e.action}` : null,
        e.type ? `type=${e.type}` : null,
        e.title ? `title=${e.title}` : null,
        e.from ? `from=${e.from}` : null,
      ].filter((item) => item !== null)

      if (!webString.length) {
        actionParameters = JSON.stringify({})
      } else {
        actionParameters = JSON.stringify({
          url: `${process.env.REACT_APP_ACTION_LINK_START}web?${webString.join('&')}`,
        })
      }
      
      break
    case LANDING_ACTION_PARAMETER.SEARCH:
      const searchString = [e.name ? `name=${e.name}` : null, e.query ? `query=${e.query}` : null, e.from ? `from=${e.from}` : null]
      actionParameters = JSON.stringify({
        url: `${process.env.REACT_APP_ACTION_LINK_START}search?${searchString.filter((item) => item !== null).join('&')}`,
      })
      break
    case LANDING_ACTION_PARAMETER.LINK:
      const linkString = [e.action ? `action=${e.action}` : null, e.from ? `from=${e.from}` : null].filter((item) => item !== null)
      if (!linkString.length) {
        actionParameters = JSON.stringify({})
      } else {
      actionParameters = JSON.stringify({
        url: `${process.env.REACT_APP_ACTION_LINK_START}link?${linkString.filter((item) => item !== null).join('&')}`,
      })}
      break
    case LANDING_ACTION_PARAMETER.PIXEL:
      const pixelString = [
        e.action ? `action=${e.action}` : null,
        e.name ? `name=${e.name}` : null,
        e.haveRef ? `haveRef=${e.haveRef}` : null,
        e.from ? `from=${e.from}` : null,
      ]
      actionParameters = JSON.stringify({
        url: `${process.env.REACT_APP_ACTION_LINK_START}pixel?${pixelString.filter((item) => item !== null).join('&')}`,
      })
      break
    // case LANDING_ACTION_PARAMETER.PRODUCT_CATEGORY:
    //   actionParameters = JSON.stringify({
    //     url: `${process.env.REACT_APP_ACTION_LINK_START}product?categoryCode=${e.categoryCode}${e.from ? '&from=' + e.from : ''}`,
    //   })
    //   break
    // case LANDING_ACTION_PARAMETER.E_COUPON_CATEGORY:
    //   actionParameters = JSON.stringify({
    //     url: `${process.env.REACT_APP_ACTION_LINK_START}e-coupon?categoryCode=${e.categoryCode}${e.from ? '&from=' + e.from : ''}`,
    //   })
    //   break
    // case BANNER_ACTION.PRODUCT:
    //   actionParameters = JSON.stringify({ url: `${process.env.REACT_APP_ACTION_LINK_START}product?${e.productParent?e.productParent==="BRAND"?"brandCode=":e.productParent==="CATEGORY"?"categoryCode=":"":""}${e.code?e.code+"":""}${e.productParent?"&":""}productCode=${e.url}${e.from ? "&from=" + e.from : ""}` });
    //   break;
    case LANDING_ACTION_PARAMETER.PRODUCT:
    case LANDING_ACTION_PARAMETER.PRODUCT_CATEGORY:
    case LANDING_ACTION_PARAMETER.PRODUCT_BRAND:
      const productString = [
        e.catProductCode ? `productCode=${e.catProductCode}` : null,
        e.showCategoryList ? `showCategoryList=true` : null,
        e.showBrandList ? `showBrandList=true` : null,
        e.categoryCode ? `categoryCode=${e.categoryCode}` : null,
        e.brandCode ? `brandCode=${e.brandCode}` : null,
        e.from ? `from=${e.from}` : null,
      ]
      actionParameters = JSON.stringify({
        url: `${process.env.REACT_APP_ACTION_LINK_START}product?${productString.filter((item) => item !== null).join('&')}`,
      })
      break
    case LANDING_ACTION_PARAMETER.E_COUPON:
    case LANDING_ACTION_PARAMETER.E_COUPON_CATEGORY:
    case LANDING_ACTION_PARAMETER.E_COUPON_BRAND:
    case LANDING_ACTION.COUPON:
      const ecouponString = [
        e.ecouponCode ? `ecouponCode=${e.ecouponCode}` : null,
        e.showCategoryList ? `showCategoryList=true` : null,
        e.showBrandList ? `showBrandList=true` : null,
        e.categoryCode ? `categoryCode=${e.categoryCode}` : null,
        e.brandCode ? `brandCode=${e.brandCode}` : null,
        e.from ? `from=${e.from}` : null,
      ]
      actionParameters = JSON.stringify({
        url: `${process.env.REACT_APP_ACTION_LINK_START}e-coupon?${ecouponString.filter((item) => item !== null).join('&')}`,
      })
      break
    // case LANDING_ACTION_PARAMETER.PRODUCT_BRAND:
    //   actionParameters = JSON.stringify({
    //     url: `${process.env.REACT_APP_ACTION_LINK_START}product?brandCode=${e.brandCode}${e.from ? '&from=' + e.from : ''}`,
    //   })
    //   break
    // case LANDING_ACTION_PARAMETER.E_COUPON_BRAND:
    //   actionParameters = JSON.stringify({
    //     url: `${process.env.REACT_APP_ACTION_LINK_START}e-coupon?brandCode=${e.brandCode}${e.from ? '&from=' + e.from : ''}`,
    //   })
    //   break
    // case LANDING_ACTION.COUPON:
    //   if (e.showCategoryList || e.categoryCode) {
    //     actionParameters = JSON.stringify({
    //       url: `${process.env.REACT_APP_ACTION_LINK_START}e-coupon?ecouponCode=${e.ecouponCode}${
    //         e.categoryCode ? `&categoryCode=${e.categoryCode}` : ''
    //       }${e.showCategoryList ? `&showCategoryList=true` : ''}${e.from ? '&from=' + e.from : ''}`,
    //     })
    //   } else if (e.showBrandList || e.brandCode) {
    //     actionParameters = JSON.stringify({
    //       url: `${process.env.REACT_APP_ACTION_LINK_START}e-coupon?ecouponCode=${e.ecouponCode}${e.brandCode ? `&brandCode=${e.brandCode}` : ''}${
    //         e.showBrandList ? `&showBrandList=true` : ''
    //       }${e.from ? '&from=' + e.from : ''}`,
    //     })
    //   }
    //   break
    // case BANNER_ACTION.CATEGORY_PRODUCT:
    // case BANNER_ACTION.CATEGORY_PRODUCT_MAP:
    //   actionParameters = JSON.stringify({ url: `${process.env.REACT_APP_ACTION_LINK_START}product?categoryCode=${e.url}&productCode=${e.catProductCode ? e.catProductCode : ""}${e.from ? "&from=" + e.from : ""}` });
    //   break;
    // case BANNER_ACTION.BRAND_PRODUCT:
    // case BANNER_ACTION.BRAND_PRODUCT_MAP:
    //   actionParameters = JSON.stringify({ url: `${process.env.REACT_APP_ACTION_LINK_START}product?brandCode=${e.url}&productCode=${e.brandProductCode ? e.brandProductCode : ""}${e.from ? "&from=" + e.from : ""}` });
    //   break;
    case LANDING_ACTION_PARAMETER.FOLDER:
      const folderString = [
        e.folderCode ? `folderCode=${e.folderCode}` : null,
        e.folderType ? `folderType=${e.folderType}` : null,
        e.from ? `from=${e.from}` : null,
      ]
      actionParameters = JSON.stringify({
        url: `${process.env.REACT_APP_ACTION_LINK_START}folder?${folderString.filter((item) => item !== null).join('&')}`,
      })
      break
    case LANDING_ACTION_PARAMETER.RESTAURANT:
      const restString = [e.restUrlId ? `restUrlId=${e.restUrlId}` : null, e.from ? `from=${e.from}` : null]
      actionParameters = JSON.stringify({
        url: `${process.env.REACT_APP_ACTION_LINK_START}rest?${restString.filter((item) => item !== null).join('&')}`,
      })
      break
    case LANDING_ACTION_PARAMETER.RESTAURANT_CATEGORY:
      actionParameters = JSON.stringify({
        url: `${process.env.REACT_APP_ACTION_LINK_START}shopcategory?categoryCode=${e.categoryCode}`,
      })
      break
    case LANDING_ACTION_PARAMETER.YOUTUBE:
      const youtubeString = [e.videoId ? `videoId=${e.videoId}` : null, e.title ? `title=${e.title}` : null]
      actionParameters = JSON.stringify({
        url: `${process.env.REACT_APP_ACTION_LINK_START}youtube?${youtubeString.filter((item) => item !== null).join('&')}`,
      })
      break
    default:
      actionParameters = ''
      break
  }
  return actionParameters
}

export const parseLandingParameters = (actionParameters) => {
  let parsedActionParameters = JSON.parse(actionParameters)

  // console.log('json parse', parsedActionParameters)

  if (parsedActionParameters.url) {
    // const [firstItem, secondItem] = parsedActionParameters.url.split('?')
    const actionParamPairs = parsedActionParameters.url.split('?')
    // console.log('actionParamPairs', actionParamPairs)
    const urlParams = queryString.parse(actionParamPairs[1])
    parsedActionParameters = { ...parsedActionParameters, ...urlParams }
  }

  // console.log('return', parsedActionParameters)

  return parsedActionParameters
}
