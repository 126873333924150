import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Box, Collapse } from '@mui/material'
import { useState } from 'react'

export default function MUICollapse({ children, title }) {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <>
      <Box
        sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 700, height: '30px' }}
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
        {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </Box>
      <Collapse in={isOpen}>{children}</Collapse>
    </>
  )
}
