import { LANDING_ACTION, LANDING_ITEM_ACTION } from '../Constants/constants'
import { landingItemValidationConstants } from '../Constants/validationConstants'
import { isValidDeepLink, isWebViewParametersValid } from '../Page/Landing/LandingSection/landingUtil'
import { isWebActionValidUrl } from '../helper/util'

class LandingItemValidation {
  version
  errorMap
  landingItemErrorMap
  valid

  constructor() {
    if (!LandingItemValidation.instance) {
      this.version = 0
      this.valid = true
      this.errorMap = new Map()
      this.landingItemErrorMap = new Map()
      LandingItemValidation.instance = this
    }
    return LandingItemValidation.instance
  }

  validateLandingItem(landingItem) {
    this.resetErrorMessages()
    this.handleValidateLandingItem(landingItem)
    this.version++
  }

  validateLandingItemBundle(landingItem) {
    this.resetErrorMessages()
    this.handleValidateLandingItemBundle(landingItem)
    this.version++
  }

  resetErrorMessages(type, id) {
    switch (type) {
      case 'reset_param':
        this.landingItemErrorMap.delete(`${id}-${landingItemValidationConstants.KEY_PARAMETERS}`)
        this.landingItemErrorMap.delete(`${id}-${landingItemValidationConstants.KEY_CHILDCODE}`)
        this.landingItemErrorMap.delete(`${id}-${landingItemValidationConstants.KEY_SERVICE_TYPE}`)
        this.landingItemErrorMap.delete(`${id}-${landingItemValidationConstants.KEY_CODE}`)
        break
      default:
        this.errorMap = new Map()
        this.landingItemErrorMap = new Map()
        this.valid = true
        break
    }
  }

  getVersion() {
    return this.version
  }

  isValid() {
    return this.valid
  }

  getErrorMessages(key) {
    // console.log(this.landingItemErrorMap.get(key))
    return this.landingItemErrorMap.get(key)
  }

  handleValidateLandingItemBundle(landingItemBundle) {
    for (const landingItem of landingItemBundle) {
      const id = landingItem.id ? landingItem.id : landingItem.customId
      if (!landingItem.itemType) {
        this.setLandingItemErrorMapMessages(`${id}-${landingItemValidationConstants.KEY_ITEM_ERROR}`, 'Missing Field(s)')
      }
    }
  }

  handleValidateLandingItem(landingItem) {
    const id = landingItem.id ? landingItem.id : landingItem.customId

    //title
    if (
      !(landingItem.tcTitle && landingItem.enTitle && landingItem.scTitle) &&
      !(!landingItem.tcTitle && !landingItem.enTitle && !landingItem.scTitle)
    ) {
      if (!landingItem.tcTitle) {
        this.setLandingItemErrorMapMessages(landingItemValidationConstants.KEY_TC_TITLE, 'Missing TC TITLE')
      }
      if (!landingItem.enTitle) {
        this.setLandingItemErrorMapMessages(landingItemValidationConstants.KEY_EN_TITLE, 'Missing EN TITLE')
      }
      if (!landingItem.scTitle) {
        this.setLandingItemErrorMapMessages(landingItemValidationConstants.KEY_SC_TITLE, 'Missing SC TITLE')
      }
    }

    //subTitle
    if (
      !(landingItem.tcSubTitle && landingItem.enSubTitle && landingItem.scSubTitle) &&
      !(!landingItem.tcSubTitle && !landingItem.enSubTitle && !landingItem.scSubTitle)
    ) {
      if (!landingItem.tcSubTitle) {
        this.setLandingItemErrorMapMessages(landingItemValidationConstants.KEY_TC_SUB_TITLE, 'Missing TC SUB TITLE')
      }
      if (!landingItem.enSubTitle) {
        this.setLandingItemErrorMapMessages(landingItemValidationConstants.KEY_EN_SUB_TITLE, 'Missing EN SUB TITLE')
      }
      if (!landingItem.scSubTitle) {
        this.setLandingItemErrorMapMessages(landingItemValidationConstants.KEY_SC_SUB_TITLE, 'Missing SC SUB TITLE')
      }
    }

    //image
    if (!landingItem.tcImage || !landingItem.enImage || !landingItem.scImage) {
      if (!landingItem.tcImage) {
        this.setLandingItemErrorMapMessages(landingItemValidationConstants.KEY_TC_IMAGE, 'Missing TC IMAGE')
      }
      if (!landingItem.enImage) {
        this.setLandingItemErrorMapMessages(landingItemValidationConstants.KEY_EN_IMAGE, 'Missing EN IMAGE')
      }
      if (!landingItem.scImage) {
        this.setLandingItemErrorMapMessages(landingItemValidationConstants.KEY_SC_IMAGE, 'Missing SC IMAGE')
      }
    }

    //sequence
    if (landingItem.sequence) {
      //not number
      if (isNaN(landingItem.sequence) || landingItem.sequence < 0) {
        this.setLandingItemErrorMapMessages(landingItemValidationConstants.KEY_SEQUENCE, 'Invalid sequence')
      }
    }

    //Display date

    if (landingItem.startDisplayTimestamp && landingItem.endDisplayTimestamp) {
      const startDisplayTimestamp = Number(landingItem.startDisplayTimestamp)
      const endDisplayTimestamp = Number(landingItem.endDisplayTimestamp)

      if (isNaN(startDisplayTimestamp)) {
        this.setLandingItemErrorMapMessages(`${id}-${landingItemValidationConstants.KEY_START_DISPLAY_TIMESTAMP}`, 'Invalid start display timestamp')
      } else if (!isNaN(startDisplayTimestamp) && !isNaN(endDisplayTimestamp) && startDisplayTimestamp >= endDisplayTimestamp) {
        this.setLandingItemErrorMapMessages(
          `${id}-${landingItemValidationConstants.KEY_START_DISPLAY_TIMESTAMP}`,
          'Start display timestamp is larger than end display timestamp'
        )
      }
      if (isNaN(endDisplayTimestamp)) {
        this.setLandingItemErrorMapMessages(`${id}-${landingItemValidationConstants.KEY_END_DISPLAY_TIMESTAMP}`, 'Invalid end display timestamp')
      }
    }

    if (!landingItem.status) {
      this.setLandingItemErrorMapMessages(`${id}-${landingItemValidationConstants.KEY_STATUS}`, 'Missing STATUS')
    }

    switch (landingItem.displayActionType) {
      case LANDING_ITEM_ACTION.RESTAURANT:
        if (!landingItem.parameters?.restUrlId) {
          this.setLandingItemErrorMapMessages(`${id}-${landingItemValidationConstants.KEY_PARAMETERS}`, 'Missing Parameter')
        }
        break
      case LANDING_ITEM_ACTION.PRODUCT:
        break
      case LANDING_ITEM_ACTION.PRODUCT_CATEGORY:
      case LANDING_ITEM_ACTION.E_COUPON_CATEGORY:
        if (!landingItem.parameters?.categoryCode) {
          this.setLandingItemErrorMapMessages(`${id}-${landingItemValidationConstants.KEY_PARAMETERS}`, 'Missing Parameter')
        }
        break
      case LANDING_ITEM_ACTION.PRODUCT_BRAND:
      case LANDING_ITEM_ACTION.E_COUPON_BRAND:
        if (!landingItem.parameters?.brandCode) {
          this.setLandingItemErrorMapMessages(`${id}-${landingItemValidationConstants.KEY_PARAMETERS}`, 'Missing Parameter')
        }
        break
      case LANDING_ITEM_ACTION.COUPON:
        break
      case LANDING_ACTION.YOUTUBE:
        if (!landingItem.parameters?.videoId && !landingItem.parameters?.youtubeCode) {
          this.setLandingItemErrorMapMessages(`${id}-${landingItemValidationConstants.KEY_PARAMETERS}`, 'Missing Parameter')
        }
        break
      case LANDING_ACTION.RESTAURANT_CATEGORY:
        if (!landingItem.parameters?.categoryCode) {
          this.setLandingItemErrorMapMessages(`${id}-${landingItemValidationConstants.KEY_PARAMETERS}`, 'Missing Parameter')
        }
        break
      case LANDING_ITEM_ACTION.SEARCH:
        break
      case LANDING_ITEM_ACTION.TRANSFORM_TICKET_FROM_PAPER:
        if (!landingItem.parameters?.ticketId) {
          this.setLandingItemErrorMapMessages(`${id}-${landingItemValidationConstants.KEY_PARAMETERS}`, 'Missing Parameter')
        }
        break
      case LANDING_ITEM_ACTION.TRANSFORM_ECOUPON_FROM_PAPER:
        if (!landingItem.parameters?.infoId) {
          this.setLandingItemErrorMapMessages(`${id}-${landingItemValidationConstants.KEY_PARAMETERS}`, 'Missing Parameter')
        }
        break
      case LANDING_ITEM_ACTION.FOLDER:
        // if (!landingItem.parameters?.folderCode) {
        //   this.setLandingItemErrorMapMessages(`${id}-${landingItemValidationConstants.KEY_PARAMETERS}`, 'Missing Parameter')
        // }
        break
      case LANDING_ITEM_ACTION.VIDEO:
        if (!landingItem.parameters?.displayActionType) {
          this.setLandingItemErrorMapMessages(`${id}-${landingItemValidationConstants.KEY_PARAMETERS}`, 'Missing Parameter')
        }
        break
      case LANDING_ITEM_ACTION.SPRINGBOARD:
        if (!landingItem.parameters?.displayActionType) {
          this.setLandingItemErrorMapMessages(`${id}-${landingItemValidationConstants.KEY_PARAMETERS}`, 'Missing Parameter')
        }
        break
      case LANDING_ITEM_ACTION.EXPRESS_TICKET_VOUCHER:
        if (!landingItem.parameters?.groupCode) {
          this.setLandingItemErrorMapMessages(`${id}-${landingItemValidationConstants.KEY_PARAMETERS}`, 'Missing Parameter')
        }
        break
      case LANDING_ITEM_ACTION.MY_TICKET:
        if (!landingItem.parameters?.refId) {
          this.setLandingItemErrorMapMessages(`${id}-${landingItemValidationConstants.KEY_PARAMETERS}`, 'Missing Parameter')
        }
        if (!landingItem.parameters?.serviceType) {
          this.setLandingItemErrorMapMessages(landingItemValidationConstants.KEY_SERVICE_TYPE, 'Missing Service Type')
        } else if (landingItem.parameters.serviceType === 'RACK_PRODUCT' && !landingItem.parameters.type) {
          this.setLandingItemErrorMapMessages(`${id}-${landingItemValidationConstants.KEY_TYPE}`, 'Missing Type')
        }
        break
      case LANDING_ITEM_ACTION.GIFT:
        if (!landingItem.parameters?.refId) {
          this.setLandingItemErrorMapMessages(`${id}-${landingItemValidationConstants.KEY_PARAMETERS}`, 'Missing Parameter')
        }
        if (!landingItem.parameters?.code) {
          this.setLandingItemErrorMapMessages(`${id}-${landingItemValidationConstants.KEY_CODE}`, 'Missing Code')
        }
        if (!landingItem.parameters?.serviceType) {
          this.setLandingItemErrorMapMessages(landingItemValidationConstants.KEY_SERVICE_TYPE, 'Missing Service Type')
        } else if (landingItem.parameters['serviceType'] === 'RACK_PRODUCT' && !landingItem.parameters['type']) {
          this.setLandingItemErrorMapMessages(`${id}-${landingItemValidationConstants.KEY_TYPE}`, 'Missing Type')
        }
        break
      case LANDING_ITEM_ACTION.SHARE:
        if (!landingItem.parameters?.serviceType) {
          this.setLandingItemErrorMapMessages(`${id}-${landingItemValidationConstants.KEY_SERVICE_TYPE}`, 'Missing Service Type')
        }
        break
      case LANDING_ITEM_ACTION.LINK:
        if (!landingItem.parameters?.url) {
          this.setLandingItemErrorMapMessages(`${id}-${landingItemValidationConstants.KEY_PARAMETERS}`, 'Missing Parameters')
        }
        if (!isWebActionValidUrl(landingItem.parameters?.url)) {
          this.setLandingItemErrorMapMessages(`${id}-${landingItemValidationConstants.KEY_PARAMETERS}`, 'Invalid Parameters')
        }
        break
      case LANDING_ITEM_ACTION.WEB_VIEW:
        console.log('landingItem', landingItem)
        if (!landingItem.parameters?.url) {
          console.log('HI')
          this.setLandingItemErrorMapMessages(`${id}-${landingItemValidationConstants.KEY_PARAMETERS}`, 'Missing Parameters')
        } else if (!isWebActionValidUrl(landingItem.parameters?.url)) {
          this.setLandingItemErrorMapMessages(`${id}-${landingItemValidationConstants.KEY_PARAMETERS}`, 'Invalid Parameters')
        } else if (isValidDeepLink(landingItem.parameters?.url)) {
          const { isValid, errorKey, errorMessage } = isWebViewParametersValid(landingItem.parameters.url)
          if (!isValid) {
            this.setLandingItemErrorMapMessages(`${id}-${errorKey}`, errorMessage)
          }
        }
        break
      default:
    }
  }

  setLandingItemErrorMapMessages(key, errorMessages, valid = false) {
    // console.log('setLandingItemErrorMapMessages', key, errorMessages)
    this.landingItemErrorMap.set(key, errorMessages)
    if (!valid) {
      this.valid = false
    }
  }
}

const validation = new LandingItemValidation()
export default validation
