import { useState, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'

import { constants } from '../../../../Constants/constants'
import { post } from '../../../../helper/baseFetch'
import { setSystemGroup, setSystemGroupValue, addSystemGroupEmailList, removeSystemGroupEmailList } from '../../../../actions/SystemGroupAction'
import { EditCreateDisplay } from '../../../EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../../Input/TempTextInput'
import { TempBtn } from '../../../Input/TempBtn'
import { TempAutoSelectInput } from '../../../Input/TempAutoSelectInput'
import { DialogWrapper } from '../../../DialogWrapper'
import { helperTextStyles } from '../../../../helper/util'
import SystemGroupValidation from '../../../../validations/systemGroupValidation'
import { systemGroupValidationConstants } from '../../../../Constants/validationConstants'
import { AddRestaurantButton } from './AddRestaurantButton'
import { getString } from '../../../../helper/util'

export const ProductSystemGroupCreate = () => {
  const dispatch = useDispatch()
  const store = useStore()
  const history = useHistory()
  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)
  const [clear, setClear] = useState(false)
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const resource = 'productCreateSystemGroupList'
  const savedSystemGroup = useSelector((state) => state.systemGroup.ui.systemGroup)
  // const [brandInputList, setBrandInputList] = useState(_.cloneDeep(constants.BRAND_INPUT));
  const helperTextStylesClasses = helperTextStyles()
  const [, setErrorVersion] = useState(SystemGroupValidation.getVersion())
  const [addRestaurantMap, setAddRestaurantMap] = useState(new Map())
  const [confirmRestList, setConfirmRestList] = useState([])

  const handleAddEmail = () => {
    console.log('addSystemGroupEmailList(savedSystemGroup)', addSystemGroupEmailList(savedSystemGroup))
    dispatch(addSystemGroupEmailList(savedSystemGroup))
  }

  const handleRemoveEmail = (index) => {
    dispatch(removeSystemGroupEmailList(savedSystemGroup, index))
  }

  const tabs = [
    {
      label: { resource: resource, key: 'info' },
      tabIndex: constants.SYSTEM_GROUP.INFO,
    },
  ]
  const tempEditList = [
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'name' },
      value: savedSystemGroup.name,
      name: 'name',
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: SystemGroupValidation.getErrorMessages(systemGroupValidationConstants.KEY_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { resource: resource, key: 'addEmail' },
      disabled: false,
      onClick: () => handleAddEmail(),
      name: 'addEmail',
      type: constants.TYPE.BUTTON,
      icon: <AddIcon />,
      isEndOfLine: true,
    },
  ]

  savedSystemGroup?.groupEmailList?.forEach((item, index) => {
    tempEditList.push({
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      type: constants.TYPE.FUNCTION,
      isEndOfLine: true,
      value: (
        <>
          {SystemGroupValidation.getErrorMessages(`${index}-${systemGroupValidationConstants.KEY_GROUP_EMAILS}`) && (
            <div className="error-message">
              <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
              {SystemGroupValidation.getErrorMessages(`${index}-${systemGroupValidationConstants.KEY_GROUP_EMAILS}`)}
            </div>
          )}
          <div>
            <TempTextInput
              inputSetting={{
                className: 'edit-create-input',
                size: constants.SIZE.SMALL,
                label: { resource: resource, key: 'groupEmails' },
                name: `groupEmails-${index}`,
                required: false,
                value: item,
                index: index,
              }}
              handleChange={onChangeInput}
            />
            <TempBtn
              btnSetting={{
                className: 'edit-create-btn',
                label: { key: 'deleteBtn' },
                variant: constants.STYLE.VARIANT.CONTAINED,
                color: constants.STYLE.PRIMARY,
                disabled: false,
                onClick: () => handleRemoveEmail(index),
                icon: <DeleteIcon />,
                isEndOfLine: true,
              }}
            ></TempBtn>
          </div>
        </>
      ),
    })
  })

  tempEditList.push(
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: 'RestaurantList' },
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      type: constants.TYPE.FUNCTION,
      value: (
        <AddRestaurantButton
          onChangeInput={onChangeInput}
          addRestaurantMap={addRestaurantMap}
          setAddRestaurantMap={setAddRestaurantMap}
          confirmRestList={confirmRestList}
          setConfirmRestList={setConfirmRestList}
        />
      ),
      isEndOfLine: true,
    }
  )

  const content = [
    {
      tab: tempEditList.map((inputSetting, index) => {
        let content
        switch (inputSetting.type) {
          case constants.TYPE.SELECT:
            content = <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
            break
          case constants.TYPE.BUTTON:
            content = <TempBtn btnSetting={inputSetting} index={index} />
            break
          case constants.TYPE.FUNCTION:
            content = inputSetting.value
            break
          case constants.TYPE.TITLE:
            content = (
              <div className={`${inputSetting.className + ' sub-title'}`}>
                {getString(lang, inputSetting.value.resource, inputSetting.value.key, theme)}
              </div>
            )
            break
          default:
            content = <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
        }
        return (
          <div className="edit-display-wrapper" key={index}>
            {content}
            {inputSetting.isEndOfLine && <div></div>}
          </div>
        )
      }),
      btn: true,
    },
  ]

  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'save' },
      onClick: onClickSave,
      className: 'margin-left-16',
      icon: <SaveIcon />,
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'clear' },
      disabled: false,
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
  ]

  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push('/' + constants.PATH.PRODUCT.SYSTEM_GROUP)
  }

  function onChangeInput(name, e) {
    if (name.startsWith('groupEmails')) {
      const index = name.split('-')[1]
      dispatch(setSystemGroupValue('groupEmails', savedSystemGroup, index, e))
      return
    }
    dispatch(setSystemGroupValue(name, savedSystemGroup, '', e))
  }

  function onClickSave() {
    SystemGroupValidation.validateEditSystemGroup(savedSystemGroup)
    if (SystemGroupValidation.isValid()) {
      const selectedIdList = confirmRestList.map((item) => item.id)
      const tempt = { ...savedSystemGroup, restUrlIdList: selectedIdList }
      delete tempt.id
      post(
        constants.PATH.PRODUCT.SYSTEM_GROUP,
        tempt,
        (payload) => {
          setDialog({
            open: true,
            onClose: onClickBackToCreate,
            dialogContentText: { key: 'createSuccess' },
            dialogActions: [
              { label: { key: 'backCreate' }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
              { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
      SystemGroupValidation.resetErrorMessages()
    } else {
      setErrorVersion(SystemGroupValidation.getVersion())
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    let tempInput = _.cloneDeep(constants.SYSTEM_GROUP_INPUT)
    dispatch(setSystemGroup(tempInput))
    setClear(false)
    SystemGroupValidation.resetErrorMessages()
  }, [clear === true]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <EditCreateDisplay
        code=""
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        display="create"
      />
      {dialog && dialog.open && <DialogWrapper dialogInfo={dialog} />}
    </>
  )
}
