import { Box, Card, Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material'
import MUILabel from '../../Component/common/label/MUILabel'
import { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
import { getOne } from '../../helper/baseFetch'
import { useStore } from 'react-redux'
import { useParams } from 'react-router-dom'

export default function QueueDashboard() {
  const [tabIdx, setTabIdx] = useState(0)
  const store = useStore()
  const [openTimeSectionObj, setOpenTimeSectionObj] = useState({})
  const [openDeviceObj, setOpenDeviceObj] = useState({})
  const [data, setData] = useState({})
  const [isInitiated, setIsInitiated] = useState(false)
  const { id } = useParams()

  const { queueStatus, heartbeat, autoTask, autoSwitchTimeSection, autoRequestTicketStatus, currentTimeSection, timeSectionList, deviceList } = data

  const onToggleTimeSection = (timeSectionId) => {
    setOpenTimeSectionObj((prevOpenTimeSectionObj) => ({
      ...prevOpenTimeSectionObj,
      [timeSectionId]: !prevOpenTimeSectionObj[timeSectionId],
    }))
  }

  const onToggleDevice = (idx) => {
    setOpenDeviceObj((prevOpenDeviceObj) => ({
      ...prevOpenDeviceObj,
      [idx]: !prevOpenDeviceObj[idx],
    }))
  }

  const DataPair = ({ icon, tooltip, children, childrenType = 'default', sx }) => {
    return (
      <Stack direction="row" gap={1} sx={{ height: '20px', ...sx }}>
        <Tooltip title={tooltip} followCursor>
          <Box sx={{ position: 'relative' }}>
            {childrenType === 'boolean' &&
              (children ? (
                <Iconify
                  icon="mdi:tick"
                  sx={{
                    width: '12px',
                    height: '12px',
                    color: '#fff',
                    backgroundColor: '#62ad54',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    borderRadius: '6px',
                  }}
                />
              ) : (
                <Iconify
                  icon="mdi:close"
                  sx={{
                    width: '12px',
                    height: '12px',
                    color: '#fff',
                    backgroundColor: '#7a0e1c',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    borderRadius: '6px',
                  }}
                />
              ))}
            <Iconify icon={icon} sx={{ width: '22px', height: '22px', color: '#a8a8a8' }} />
          </Box>
        </Tooltip>
        {childrenType === 'default' && <Box sx={{ fontSize: '14px', fontWeight: 600 }}>{children}</Box>}
      </Stack>
    )
  }

  const Iconify = ({ icon = 'grommet-icons:status-good', sx, ...other }) => {
    return <Box component={Icon} icon={icon} sx={{ ...sx, flexShrink: 0 }} {...other} />
  }

  const TimeSectionList = ({ timeSectionList, displayType = 'default' }) => {
    return (
      <Box>
        {timeSectionList.map((timeSection, idx) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              p: 1,
              gap: 1,
              borderBottom: idx !== timeSectionList.length - 1 ? 'solid 1px #eee' : '',
              '&:hover': {
                backgroundColor: '#eef5fb',
              },
            }}
          >
            <Tooltip title="Time Section Id" followCursor>
              <Box sx={{ fontSize: '16px', fontWeight: 700, backgroundColor: '#eee', borderRadius: '5px', px: '10px', height: 'fit-content' }}>
                {timeSection.timeSectionId}
              </Box>
            </Tooltip>
            <Stack direction="column" gap={1}>
              <MultiLangName nameObj={timeSection.name} />
              <Stack direction="column">
                {timeSection.tableTypeList.map((tableType) => (
                  <Card
                    key={timeSection.timeSectionId}
                    sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', p: 1, gap: 1 }}
                  >
                    <Stack direction="row" gap={1} alignItems="center">
                      <Box sx={{ width: '50px' }}>
                        <Tooltip title="Table Type" followCursor>
                          <Box
                            sx={{
                              py: '5px',
                              px: '10px',
                              fontSize: '12px',
                              fontWeight: 700,
                              backgroundColor: 'yellow',
                              width: 'fit-content',
                              borderRadius: '5px',
                            }}
                          >
                            {tableType.tableType}
                          </Box>
                        </Tooltip>
                      </Box>
                      {tableType.name && <MultiLangName nameObj={tableType.name} />}
                      {tableType.sizeSelectable && (
                        <DataPair tooltip="Size" icon="mdi:people" sx={{ width: '60px' }}>
                          {tableType.minSize}-{tableType.maxSize}
                        </DataPair>
                      )}
                      {displayType === 'default' && (
                        <DataPair tooltip="Status" icon="grommet-icons:status-good" sx={{ width: '50px' }}>
                          {tableType.status}
                        </DataPair>
                      )}
                    </Stack>
                    {displayType === 'default' && (
                      <Stack direction="row" gap={2}>
                        <Stack direction="row" gap={1}>
                          <MUILabel variant="ghost" color="secondary">
                            KIOSK
                          </MUILabel>
                          <DataPair tooltip="Status">{tableType.kioskStatus}</DataPair>
                          <DataPair tooltip="Ticket Display" childrenType="boolean" icon="carbon:ticket">
                            {tableType.kioskTicketDisplay}
                          </DataPair>
                          <DataPair tooltip="Tag Display" childrenType="boolean" icon="mdi:tag-outline">
                            {tableType.kioskTagDisplay}
                          </DataPair>
                        </Stack>
                        <Stack direction="row" gap={1}>
                          <MUILabel variant="ghost" color="secondary">
                            MOBILE
                          </MUILabel>
                          <DataPair tooltip="Status">{tableType.mobileStatus}</DataPair>
                          <DataPair tooltip="Ticket Display" childrenType="boolean" icon="carbon:ticket">
                            {tableType.mobileTicketDisplay}
                          </DataPair>
                          <DataPair tooltip="Tag Display" childrenType="boolean" icon="mdi:tag-outline">
                            {tableType.mobileTagDisplay}
                          </DataPair>
                        </Stack>
                      </Stack>
                    )}
                  </Card>
                ))}
              </Stack>
            </Stack>
          </Box>
        ))}
      </Box>
    )
  }

  const MultiLangName = ({ nameObj, tooltip }) => {
    return (
      <Tooltip title={tooltip || ''} followCursor>
        <Stack gap={2} direction="row">
          <Stack direction="row" sx={{ gap: 1 }}>
            <MUILabel variant="ghost" color="primary">
              EN
            </MUILabel>
            {nameObj.en}
          </Stack>
          <Stack direction="row" sx={{ gap: 1 }}>
            <MUILabel variant="ghost" color="primary">
              TC
            </MUILabel>
            {nameObj.tc}
          </Stack>
          <Stack direction="row" sx={{ gap: 1 }}>
            <MUILabel variant="ghost" color="primary">
              SC
            </MUILabel>
            {nameObj.sc}
          </Stack>
        </Stack>
      </Tooltip>
    )
  }

  const DeviceList = ({ deviceList }) => {
    return (
      <>
        {deviceList.map((device, idx) => {
          const isProfile = device.queueTransferProfile || device.counterProfile || device.queueProfile
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                p: 1,
                borderBottom: idx !== deviceList.length ? 'solid 1px #eee' : '',
                gap: 1,
                '&:hover': {
                  backgroundColor: '#eef5fb',
                },
              }}
            >
              <Box
                component="span"
                sx={{
                  fontSize: '16px',
                  fontWeight: 700,
                  whiteSpace: 'pre-wrap',
                  width: '200px',
                  wordBreak: 'break-word',
                  display: 'inline-flex',
                }}
              >
                {device.type === 'kiosk' && (
                  <Tooltip title="KIOSK" followCursor>
                    <Box>
                      <Iconify icon="fluent:kiosk-24-filled" sx={{ width: '22px', height: '22px', color: '#a8a8a8', mr: 1 }} />
                    </Box>
                  </Tooltip>
                )}
                {device.type === 'pr' && (
                  <Tooltip title="PR" followCursor>
                    <Box>
                      <Iconify icon="icon-park:ipad" sx={{ width: '22px', height: '22px', mr: 1 }} />
                    </Box>
                  </Tooltip>
                )}
                {device.type === 'tv' && (
                  <Tooltip title="TV" followCursor>
                    <Box>
                      <Iconify icon="icon-park-outline:tv-one" sx={{ width: '22px', height: '22px', color: '#a8a8a8', mr: 1 }} />
                    </Box>
                  </Tooltip>
                )}
                {device.type === 'STAFF_PR' && (
                  <Tooltip title="STAFF PR" followCursor>
                    <Box sx={{ mr: 1, position: 'relative' }}>
                      <Iconify icon="icon-park:ipad" sx={{ width: '22px', height: '22px' }} />
                      <Iconify
                        icon="octicon:feed-person-16"
                        sx={{
                          width: '14px',
                          height: '14px',
                          color: '#a8a8a8',
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          backgroundColor: '#fff',
                          borderRadius: '7px',
                        }}
                      />
                    </Box>
                  </Tooltip>
                )}
                {device.name}
              </Box>
              <Stack direction="column" gap={1}>
                {device.counterName && <MultiLangName nameObj={device.counterName} tooltip="Counter Name" />}
                {isProfile && (
                  <Stack direction="column" gap={1} sx={{ flexWrap: 'wrap' }}>
                    {device.queueTransferProfile && (
                      <Card sx={{ display: 'flex', flexDirection: 'column', p: 1, gap: 1 }}>
                        <Stack gap={1} direction="row">
                          <MUILabel variant="ghost" color="secondary">
                            QUEUE TRANSFER PROFILE
                          </MUILabel>
                          <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>{device.queueTransferProfile.profileCode}</Typography>
                        </Stack>
                        {device.queueTransferProfile.timeSectionList &&
                          (device.queueTransferProfile.timeSectionList.length > 0 ? (
                            <TimeSectionList timeSectionList={device.queueTransferProfile.timeSectionList} displayType="queueTransferProfile" />
                          ) : (
                            <Box sx={{ fontSize: '14px', fontWeight: 700 }}>No queue for today</Box>
                          ))}
                      </Card>
                    )}
                    {device.queueProfile && (
                      <Card sx={{ display: 'flex', flexDirection: 'column', p: 1, gap: 1 }}>
                        <Stack gap={1} direction="row">
                          <MUILabel variant="ghost" color="secondary">
                            QUEUE PROFILE
                          </MUILabel>
                          <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>{device.queueProfile.profileCode}</Typography>
                        </Stack>
                        {device.queueProfile.timeSectionList && device.queueProfile.timeSectionList.length > 0 && (
                          <TimeSectionList timeSectionList={device.queueProfile.timeSectionList} displayType="queueProfile" />
                        )}
                      </Card>
                    )}
                    {device.counterProfile && (
                      <Card sx={{ display: 'flex', flexDirection: 'column', p: 1, gap: 1 }}>
                        <Stack direction="row" gap={1}>
                          <MUILabel variant="ghost" color="secondary">
                            COUNTER PROFILE
                          </MUILabel>
                          <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>{device.counterProfile.profileCode}</Typography>
                        </Stack>
                        <Stack direction="column">
                          {device.counterProfile.counterList.map((counter, counterIdx) => (
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                borderBottom: counterIdx !== device.counterProfile.counterList.length - 1 ? 'solid 1px #eee' : '',
                                gap: 1,
                              }}
                            >
                              <MultiLangName nameObj={counter.name} />
                            </Box>
                          ))}
                        </Stack>
                      </Card>
                    )}
                  </Stack>
                )}
              </Stack>
            </Box>
          )
        })}
      </>
    )
  }

  const getCurrentStatus = () => {
    getOne(
      `shop/queue/current_status/${id}`,
      undefined,
      (payload) => {
        setData(payload)
        setIsInitiated(true)
      },
      undefined,
      store
    )
  }

  useEffect(() => {
    getCurrentStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    isInitiated && (
      <Card>
        <Stack direction="column" padding={2}>
          {currentTimeSection && (
            <Card sx={{ display: 'flex', flexDirection: 'row', p: 2, gap: 2 }}>
              <Box sx={{ fontSize: '16px', fontWeight: 700 }}>CURRENT TIME SECTION</Box>
              <Stack direction="row" gap={2}>
                <Tooltip title="Time Section Id" followCursor>
                  <Box sx={{ fontSize: '16px', fontWeight: 700, backgroundColor: '#eee', borderRadius: '5px', px: '10px' }}>
                    {currentTimeSection.timeSectionId}
                  </Box>
                </Tooltip>
                <MultiLangName nameObj={currentTimeSection.name} />
              </Stack>
            </Card>
          )}
          <Card sx={{ display: 'flex', flexDirection: 'row', p: 2, gap: 2 }}>
            <Box sx={{ fontSize: '16px', fontWeight: 700 }}>STATUS</Box>
            <Stack direction="row" gap={2.5}>
              <DataPair tooltip="Queue Status" icon="mdi:human-queue">
                {queueStatus}
              </DataPair>
              <DataPair tooltip="Heartbeat" icon="mingcute:heartbeat-line">
                {heartbeat}
              </DataPair>
              <DataPair tooltip="Auto Task" icon="fluent:clipboard-task-list-ltr-24-regular" childrenType="boolean">
                {autoTask}
              </DataPair>
              <DataPair tooltip="Auto Switch Time Section" icon="mdi:tool-time" childrenType="boolean">
                {autoSwitchTimeSection}
              </DataPair>
              <DataPair tooltip="Auto Request Ticket Status" icon="icon-park-outline:tickets-checked" childrenType="boolean">
                {autoRequestTicketStatus}
              </DataPair>
            </Stack>
          </Card>
        </Stack>
        <Tabs
          value={tabIdx}
          onChange={(e, newValue) => {
            setTabIdx(newValue)
            getCurrentStatus()
          }}
          aria-label="Shop Dashboard Tabs"
        >
          {timeSectionList && timeSectionList.length > 0 && (
            <Tab label="Time Section List" sx={{ fontSize: '14px', fontWeight: 700, textTransform: 'none' }} />
          )}
          {deviceList && deviceList.length > 0 && <Tab label="Device List" sx={{ fontSize: '14px', fontWeight: 700, textTransform: 'none' }} />}
        </Tabs>
        {tabIdx === 0 && <TimeSectionList timeSectionList={timeSectionList} onToggleTimeSection={onToggleTimeSection} />}
        {tabIdx === 1 && <DeviceList deviceList={deviceList} onToggleDevice={onToggleDevice} />}
      </Card>
    )
  )
}
